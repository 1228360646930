import React from "react"
import { PageLayout } from "../components/Layout"
import TextSectionBackground from "../components/TextSectionBackground"
import { SectionHeader, SubSectionHeader } from "../components/Typography"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO"
import Img from "gatsby-image"
import { ActionButton } from "../components/buttons"
import { ButtonBasic } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import "./blog.scss"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import TentsGrid from "../components/TentsGrid"


const SpecialWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 50px;
  p {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    line-height: 1.5;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    picture {
      img {
        cursor: pointer;
        transition: transform 0.5s, filter 0.25s !important;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;

`

const Column = styled.div`
  width: calc(50% - 60px);
  margin: 0 30px;

`

const Description = styled.div`
  p {
    font-size: 16px;
  }

`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 6% 0 2%;
  button {
    margin: 8px 10px;
  }

`

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => (
  <p>{children}</p>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

export default ({ data }) => {
  
  console.log(data.contentfulSpecial);

  return (
    <PageLayout>
      <SEO title={data.contentfulSpecial.seoTitle} description={data.contentfulSpecial.metaDescription} />
        <TextSectionBackground pa="4% 10% 2%">
          <SpecialWrapper>
            <SectionHeader>
              Specials
            </SectionHeader>
            <SubSectionHeader style={{fontSize:"28px"}}>
                {data.contentfulSpecial.header}
            </SubSectionHeader>
            <ColumnWrapper>
              <Column>
                <Description>
                {documentToReactComponents(
                  data.contentfulSpecial.description.json,
                  options
                )}
                </Description>
              </Column>
              <Column>
                <Img fluid={data.contentfulSpecial.headerPic.fluid}></Img>
              </Column>
            </ColumnWrapper>            
            <ButtonWrapper>
              <EnquireButton />
              <ActionButton>
                <a
                  href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Check Availability
                </a>
              </ActionButton>
              <ButtonBasic>
                <a
                  href="/RackRates.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Download Rates Sheet
                </a>
              </ButtonBasic>
            </ButtonWrapper>                       
          </SpecialWrapper>
        </TextSectionBackground>
        <TextSectionBackground pa="4% 10%" style={{background:"#ABA17B"}}>
          <SubSectionHeader style={{fontSize:"28px" , color:"white"}}>
            Newly constructed Luxury tented accommodation
          </SubSectionHeader>
          <SpecialWrapper style={{textAlign:"left" , color:"white"}}>
            <p>
            We are currently undertaking major renovations to our accommodation at the lodge. 
            The renovation will see the quality of the accommodation improve significantly. 
            </p>
            <p>
              The first stage of the renovation should be completed by the middle of June if 
              everything goes smoothly. This will see the first 4 tents completed. The next 
              stage will be building an extra 3 tents, and should be completed by around October.
            </p>
          </SpecialWrapper>
          <SpecialWrapper>
            <TentsGrid></TentsGrid>
          </SpecialWrapper>          
        </TextSectionBackground>
    </PageLayout>
  )
}

export const query = graphql`
  query SpecialByID($slug: String) {
    contentfulSpecial(slug: { eq: $slug }) {
      header
      seoTitle
      metaDescription
      description {
        json
      }
      headerPic {
        fluid(maxWidth: 920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    allContentfulSpecial {
      nodes {
        header
        slug
        picture {
          fixed(width: 300, height: 200) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`
