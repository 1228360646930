import styled from "styled-components"
import React, { useState } from "react"
import Img from "gatsby-image"
import LightBox from "./lightbox"

const GridContainer = styled.div`
  margin-bottom: 5rem;
  button {
    padding: 0;
    border: none;
    background: none;
  }
  .grow {
    width: calc(50% - 20px);
    margin: 10px;
    @media only screen and (max-width: 800px) {
      width: calc(100% - 20px);
    }
    
    picture {
      img {
        cursor: pointer;
        transition: transform 0.5s, filter 0.25s !important;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
`

const ImageGrid = ({ data, subHeader, id }) => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = (i) => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => (e) => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => (e) => {
    setSelectedImage((i + 1) % length)
  }

  return (
    <>
      <GridContainer>
          {data.images.map((image, i) => (
            <button
              key={image.contentful_id}
              className="grow"
              onClick={() => handleOpen(i)}
            >
              <Img fluid={image.fluid}></Img>
            </button>
          ))}
        {showLightbox && selectedImage !== null && (
          <LightBox
            images={data.images}
            handleClose={handleClose}
            handleNextRequest={handleNextRequest}
            handlePrevRequest={handlePrevRequest}
            selectedImage={selectedImage}
          />
        )}
      </GridContainer>
    </>
  )
}

export default ImageGrid
